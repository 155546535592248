.teste-webhook {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 20px 10px;
  width: 200px;
}

.handle {
  position: fixed;
  top: 50%;
  background-color: #3ccfa0;
  border: 0;
}

.webhhookTitle {
  margin-top: -10px;
  font-size: 8px;
  font-weight: bold;
}
.webhookContent {
  font-size: 7px;
  max-width: 180px;
  background-color: #eaeaea;
  border-radius: 3px;
  margin-top: 5px;
  padding: 3px;
  min-height: 10px;
  overflow: hidden;
}
