body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edgebutton {
  width: 12px !important;
  height: 12px !important;
  background: #eee;
  border: none !important;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  display: grid;
  place-content: center;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}

.react-flow__edge path {
  stroke-dasharray: none;
  animation: none;
}

.react-flow__edge:hover path:first-child{
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}

g.react-flow__edge.react-flow__edge-buttonedge.nopan > path {
  stroke: #3ccfa0;
}

g.react-flow__edge.react-flow__edge-buttonedge.nopan[data-testid*="web-error"] > path,
g.react-flow__edge.react-flow__edge-buttonedge.nopan[data-testid*="false"] > path {
  stroke: #d04881;
}

g.react-flow__edge.react-flow__edge-buttonedge.nopan.selected > path {
  stroke: #30ca99;
  stroke-dasharray: 5;
  stroke-width: 5 !important;
  animation: dashdraw 0.5s linear infinite;
  opacity: 1!important;
}

g.react-flow__edge.react-flow__edge-buttonedge.nopan.selected[data-testid*="web-error"] > path,
g.react-flow__edge.react-flow__edge-buttonedge.nopan.selected[data-testid*="false"] > path {
  stroke: #ca1f4a;
}

.react-flow__edges:has(.react-flow__edge.selected) .react-flow__edge:not(.selected) {
  opacity: 0.15;
}

.react-flow__edges:has(.react-flow__edge.selected) .react-flow__edge:not(.selected) > path {
  stroke: #ffffff7a;
}

.react-flow__node {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 4px;
  width: 170px;
  z-index: 0;
}

.node-header-buttons > div:hover{
    filter: brightness(0.8);
}

.input-group-help {
  display: flex;
  align-items: center;
  gap: 5px;
}

.inputText:disabled {
  cursor: not-allowed;
}