.backdrop-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.backdrop-modal.hide {
  display: none;
}

.modal-container {
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  max-height: 80%;
  overflow: auto;
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid #bbb;
  display: flex;
  gap: 5px;
  text-transform: uppercase;
  padding: 20px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
  }

  & > div.modal-header--close {
    border-radius: 4px;
    color: #5c7080;
    cursor: pointer;
    margin-left: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > div.modal-header--close:hover{
    background-color: #e5e5e5;
  }

  & div span {
    color: #45496e;
    font-weight: 500;
  }
}

.modal-content, .modal-footer{
  padding: 2rem;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.modal-footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.setting-group {
  border-bottom: 1px solid #ccc;
}

.setting-group + .setting-group {
  margin-top: 15px;
}

.setting-group h3 {
  margin: 0 0 1em 0;
  color: #454962;
}

.setting-group-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.setting-group-title button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.setting-group-title button:hover svg {
  fill: #999;
}

.setting-group .help + div {
  margin-top: 15px;
}

.help {
  font-size: 13px;
  opacity: 0.5;
  
  & span {
    display: block;
  }
}

.inputs-group {
  display: flex;
  flex-direction: column;
  gap: 5px;

  & label {
    color: #45496e;
  }

  & > div {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  & .input-group select {
    margin-bottom: 10px
  }
}

.inputs-group > div + div {
  margin-top: 5px;
}

.inputs-group > div:last-child{
  margin-bottom: 15px;
}

.inputs-group .input-group {
  flex-grow: 1;
  width: 50%;
}

.inputs-group button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  margin-bottom: 10px;
  margin-top: auto;
}

.inputs-group button:hover svg{
  fill: #5e5b5b;
}