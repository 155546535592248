.teste-question {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 20px 10px;
  width: 150px;
}

.handle {
  position: fixed;
  top: 50%;
  background-color: #3ccfa0;
  border: 0;
}

.questionTitle {
  margin-top: -10px;
  font-size: 8px;
  font-weight: bold;
}