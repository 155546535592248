.teste-conditional {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 5px;
  height: 52px;
  padding: 20px 10px;
  width: 150px;
}

.handleTrue {
  border: 0;
  position: fixed;
  top: 25%;
  background-color: #3ccfa0;
}
.handleFalse {
  border: 0;
  position: fixed;
  top: 75%;
  background-color: #d04881;
}

.conditionalTitle {
  margin-top: -10px;
  font-size: 8px;
  font-weight: bold;
}

.conditionalContent {
  font-size: 7px;
  border-radius: 3px;
  padding: 5px;
}
