.teste-message {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 4px;
  width: 150px;
}

.handle {
  width: 8px;
  height: 8px;
  position: fixed;
  top: 50%;
  background-color: #3ccfa0;
}

.sendMessageTitle {
  font-size: 8px;
  font-weight: 500;
}
.sendMessageContent {
  font-size: 7px;
  background-color: #edeef9;
  border-radius: 4px;
  overflow: hidden;
  padding: 7px;
  min-height: 10px;
}

.sendMessageContent .opt-variable-modal {
  font-size: 7px;
}

.sendMessageContent div {
  background-color: #fff;
  padding: 11px;
  border-radius: 2px;
  max-height: 100px;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  word-break: break-word;
}

.node-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
}

.node-header > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.react-flow__node .nodeRef {
  font-size: 6px;
  color: #c4c8dd;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
}

.react-flow__node .nodeRef label {
  pointer-events: none;
}

.react-flow__node .nodeRef button {
  width: 12px;
  height: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.react-flow__node .nodeRef button svg {
  width: 6px;
  height: 6px;
  pointer-events: none;
}

.react-flow__node .nodeRef button:hover svg {
  fill: #999;
}

.questionButtons {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 7px;
  background-color: #edeef9;
  gap: 5px;
}

.questionButtons > div {
  color: #fff;
  background-color: #EC5494;
  position: relative;
}

.questionButtons > div .handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
}

.questionButtons > div .handle svg {
  pointer-events: none;
  user-select: none;
}

.react-flow__handle {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.questionButtons .item-last {
  background-color: #7075A7;
}