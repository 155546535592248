.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dndflow aside .description {
  margin: 10px 0;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: background-color 200ms ease;
}

.dndflow .dndnode:hover {
  background-color: #ccc;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.react-flow__attribution {
  display: none;
}

.variables {
  margin-top: 20px;
  border-top: 1px solid #ccc;
}

.variables .var-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.variables .variablesSet {
  background-color: #ececec;
  height: calc(70vh - 300px);
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  box-sizing: border-box;
}

.variables .variablesSet .variable-item {
  display: flex;
  align-items: center;
}

.variables .variablesSet .variable-item .variable-action {
  cursor: pointer;
  margin-left: 10px;
  color: #000;
}

.variables .variablesSet .variable-item .variable-action:hover {
  color: #3f3f3f;
}

.variables .variablesSet .item {
  background-color: #454b6b;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  cursor: default;
}

.variables .variablesSet .defaultVar {
  background-color: #f67f31;
}

.actions {
  overflow: auto;
  flex-grow: 1;
  padding: 5px;
}

.btnCreateVar {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #59b159;
  border-radius: 4px;
  color: #fff;
}

.btnCreateVar:hover {
  background-color: #398d39;
}

.nodrag.nopan.target.connectable {
  background-color: #454b6b;
  width: 11px;
  height: 11px;
  margin-left: -2px;
  z-index: 9;
}

.nodeSidebar {
  background-color: rgb(210, 211, 217);
  color: #45496e;
  border: 0 !important;
  border-radius: 5px;
}

.titleBot {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  font-size: 35px;
  pointer-events: none;
  color: #7a809b;
}
