.teste-start {
    font-size: 12px;
    background: #f5f5f5;
    border-radius: 5px;
    text-align: center;
    padding: 20px 30px;
  }
  
  .handle{
    position: fixed;
    top: 50%;
    background-color: #3CCFA0;
    border: 0;
  } 