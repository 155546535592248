.modalBlackout {
  z-index: 1800;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modalDiv {
  z-index: 2000;
  background-color: #f8f8f8;
  width: 600px;
  position: fixed;
  top: 3%;
  left: 0;
  bottom: 3%;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
}

.modalHide {
  display: none;
}
/* "DM Sans", sans-serif */

.modalHeader {
  padding: 20px;
  border-bottom: 1px solid #bbbbbb;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
}

.modalHeader > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
}

.modalHeader .modalHeader-close{ 
  margin-left: auto;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  color: #5c7080;
}

.modalHeader .modalHeader-close:hover {
  background-color: #e5e5e5;
}

.modalHeader div span {
  color: #45496e;
  font-weight: 500;
}

.modalHeader .modalHeader-link {
  color: rgb(99, 97, 240);
}

.modalHeader .modalHeader-link a {
  display: flex;
  gap: 4px;
  font-size: 12px;
  text-decoration: none;
  color: currentColor;
}

.modalHeader .modalHeader-link a:hover {
  text-decoration: underline;
}

.modalContent {
  /* margin: 0 auto; */
  max-height: 80%;
  overflow-y: auto;
  padding: 20px 15px 0 15px;
  flex-grow: 1;
}

.modalContent  label {
  color: #45496e;
}

.modalBottom {
  position: sticky;
  bottom: 0;
  text-align: center;
  padding: 20px 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btnModal {
  border: 0;
  color: #fff;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #fff;
}

.btnCancel {
  border-color: #d04881;
  color: #d04881;
}

.btnCancel:hover {
  background-color: #d04881;
  color: #fff;
}

.btnSaveModal {
  background-color: #3ccfa0;
}

.btnSaveModal:hover {
  background-color: #3ec097;
}

.inputTextArea {
  margin: 10px 0;
  height: fit-content;
  min-height: 100px;
  background: #fff;
  border: 0;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 1px 5px -1px #0000005c;
  border: 1px solid #fff;
  line-height: 24px;
}

.inputText {
  margin: 10px 0;
  background: #fff;
  border: 0;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 1px 5px -1px #0000005c;
  border: 1px solid #fff;
}

.spamSmall {
  font-size: 12px;
  color: rgb(83, 83, 83);
}

.warning {
  color: rgb(207, 88, 88);
}

.hidden {
  display: none !important;
}

.inputTextArea:focus {
  outline: 0;
  border-color: #999;
}

.inputText:focus {
  outline: 0;
  border-color: #999;
}

.opt-variable-modal {
  background-color: #454b6b;
  width: fit-content;
  padding: 3px 5px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 2px;
  color: #fff;
  border: 0;
}

.opt-variable-modal.defaultVar {
  background-color: #f67f31;
}

.opt-variable-modal:hover {
  filter: brightness(0.9);
}

.variableInText {
  background-color: #454b6b;
  padding: 3px 5px;
  width: fit-content;
  border-radius: 2px;
  color: #fff;
  margin: 0 2px;
}

.variableInText.defaultVar {
  background-color: #f67f31;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group + .input-group {
  margin-top: 18px;
}

.inputs-group .input-group + .input-group {
  margin-top: 0;
}

.input-group :is(select) {
  /* width: 90%; */
  padding: 10px;
  box-sizing: content-box;
  margin-top: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 0 1px 5px -1px #0000005c;
}

.input-group :is(select):focus {
  outline: none;
  border-color: #999;
}

label.required::after {
  content: '*';
  margin-left: 5px;
  font-size: 12px;
  color: red;
}

.content_variables {
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  margin-top: 10px;
}

.form_create_deal h3,
.form_create_task h3 {
  margin: 0;
}

.form_create_deal > div, .form_create_task > div {
  margin-top: 20px;
}

.node-question {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.node-question > div {
  display: flex;
  flex-direction: column;
}

.questionList {
  border: 1px solid #ccc;
  padding: 5px;
}

.questionList > div {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.questionList .add-new-button {
  margin-top: 18px;
  padding: 10px;
  border-radius: 200px;
  background-color: #0f2559;
  display: flex;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  color: #fff;
}

.questionList .add-new-button:hover {
  filter: brightness(0.9);
}

.questionList .add-new-button > div {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  color: #0f2559;
}

.questionList .add-new-button > span {
  margin: 0 auto;
}

.questionList > div .inputText {
  flex-grow: 1;
  padding-right: 25px;
}

.questionList > div button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  position: absolute;
  right: 5px;
}

.questionList .list-title {
  align-items: baseline;
}

.questionList .list-title input {
  width: 100%;
  box-sizing: border-box;
}

.questionList .item-section {
  width: 100%;
}

.full-input {
  position: relative;
    display: flex;
    align-items: center;
}

.question-sections {
  border: 1px solid #ccc;
  padding: 5px;
}

.section-add-item {
  width: 50%;
  margin: 0 auto;
  padding: 5px !important;
  align-items: center;
  background-color: #4a5775 !important;
}

.webhook-vars {
  display: flex;
  align-items: center;
}

.webhook-vars select,
.webhook-vars input {
  flex-grow: 1;
}

.webhook-vars svg {
  margin: 0 5px;
}

.remove-var-webhook {
  cursor: pointer;
  color: #000;
}

.remove-var-webhook:hover {
  color: #6d6d6d;
}

.node-question-groupped {
  border: 1px solid #e5e5e5;
  padding: 10px;

  & small:hover {
    text-decoration: underline;
  }

  & > label {
    margin-bottom: 15px;
  }
}

.input-group-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  & > button {
    border: none;
    background: none;
    cursor: pointer;
    margin-bottom: auto;
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 50%;

    & label {
      font-size: 14px;
    }

    & .inputTextArea {
      line-height: normal !important;
      min-height: fit-content;
      height: 38px;
            min-height: 38px;
            overflow-y: scroll;
            box-sizing: border-box;
    }
  }
}