.teste-jump {
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 20px 10px;
  width: 150px;
}

.handle {
  border: 0;
  position: fixed;
  top: 25%;
  background-color: #3ccfa0;
}
