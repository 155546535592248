.bot-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.action-button {
    padding: 10px 20px;
    border: 0;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.btnSave{
    background-color: #2ab387;
    border-color: #2ab387;
}

.settings {
    font-size: 18px;
    padding: 5px 10px;
    background-color: #0f1d3f;
}

.settings:hover {
    background-color: #0b1631;
    color: #c9c9c9;
}

.btnSave:hover {
    background-color: #278d6d;
}

.create-variable {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-top: 15px;
    gap: 3px;
}

.create-variable:hover {
    text-decoration: underline;
}